@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* convention of shadcn */
    --background: #ffffff;
    --foreground: #000000;

    --card: #ffffff;
    --card-foreground: #000000;

    --popover: #ffffff;
    --popover-foreground: #000000;

    --primary: #00a0cb;
    --primary-foreground: #f4faff;

    --secondary: #dfeaff;
    --secondary-foreground: #1b2631;

    --muted: #f5f8ff;
    --muted-foreground: #6c7d8a;

    --accent: #f5f8ff;
    --accent-foreground: #1b2631;

    --destructive: #ff4d4d;
    --destructive-foreground: #f4faff;

    --border: #e3e9f2;
    --input: #e3e9f2;
    --ring: #e3e9f2;

    --radius: 0.5rem;

    /* convention of vive */
    --background: #121216;
    --neutral-white: #ffffff;
    --neutral-0: #fbfdff;
    --neutral-10: #e3effc;
    --neutral-20: #d5e2f2;
    --neutral-30: #b6c3d6;
    --neutral-40: #6e7c8e;
    --neutral-50: #596677;
    --neutral-60: #4b596c;
    --neutral-70: #364356;
    --neutral-80: #2a3649;

    --brand-vive-40: #33c2e9;
    --brand-vive-50: #00a0cb;
    --brand-vive-60: #0589c9;

    --feedback-red: #f53232;
    --live-red: #f53232;
    --popup: #1d1d24;
    --popup-hover: #262831;

    --surface-normal: #212a39;
    --surface-highlighted: #364356;

    --border-focus: #27aac9;
  }

  button,
  input,
  input:focus {
    outline: none;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  .dark {
    /* convention of shadcn */
    --background: #ffffff;
    --foreground: #000000;

    --card: #ffffff;
    --card-foreground: #000000;

    --popover: #ffffff;
    --popover-foreground: #000000;

    --primary: #00a5ff;
    --primary-foreground: #f4faff;

    --secondary: #dfeaff;
    --secondary-foreground: #1b2631;

    --muted: #f5f8ff;
    --muted-foreground: #6c7d8a;

    --accent: #f5f8ff;
    --accent-foreground: #1b2631;

    --destructive: #ff4d4d;
    --destructive-foreground: #f4faff;

    --border: #e3e9f2;
    --input: #e3e9f2;
    --ring: #e3e9f2;

    --radius: 0.5rem;
  }

  * {
    @apply border-border;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /* defaultly set the text color to white */
    color: var(--neutral-white);
  }

  html {
    @apply snap-y snap-proximity;
  }

  body {
    @apply bg-background text-foreground;
  }

  a {
    color: var(--brand-vive-50);
    @apply text-base font-normal leading-6 tracking-[0.0025em];
  }
}

/* share component style, ex: button, input */
@layer components {
  /* convention of vive */
  .header {
    @apply font-bold leading-tight text-vive-white;
  }

  .header-1 {
    @apply header text-[50px] tracking-[0.2px];
  }

  .header-2 {
    @apply header text-[38px] tracking-[0.15px];
  }

  .header-3 {
    @apply header text-[30px] tracking-[0.12px];
  }

  .header-4 {
    @apply header text-[24px] tracking-[0.1px];
  }

  .header-5 {
    @apply header text-[20px] tracking-[0.3px];
  }

  .subheader {
    @apply font-medium leading-normal text-vive-white;
  }

  .subheader-1 {
    @apply subheader text-[18px] tracking-[0.28px];
  }

  .subheader-2 {
    @apply subheader text-[16px] tracking-[0.25px];
  }

  .subheader-3 {
    @apply subheader text-[14px] tracking-[0.22px];
  }

  .body {
    @apply font-normal leading-normal text-vive-primary-20;
  }

  .body-1 {
    @apply body text-[16px] tracking-[0.25px];
  }

  .body-2 {
    @apply body text-[14px] tracking-[0.22px];
  }

  .body-3 {
    @apply body-1 font-medium leading-normal;
  }

  .body-4 {
    @apply text-[16px] font-normal leading-[1.7] tracking-[0.3px] text-vive-primary-40;
  }

  .button {
    @apply text-[16px] font-medium leading-normal tracking-[0.4px] text-vive-primary-30;
  }

  .caption {
    @apply text-[12px] font-normal leading-[1.7] tracking-[0.3px] text-vive-primary-30;
  }

  .top-fade-out-mask {
    @apply before:absolute before:left-0 before:right-0 before:top-0 before:h-[80px] before:bg-gradient-to-b before:from-background before:to-transparent md:before:h-[120px];
  }

  .bottom-fade-out-mask {
    @apply after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[80px] after:bg-gradient-to-t after:from-background after:to-transparent md:after:h-[120px];
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .dialog-scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .dialog-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--neutral-40);
    border-radius: 4px;
  }
  .dialog-scrollbar::-webkit-scrollbar-track,
  .dialog-scrollbar::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .btn-bg-colorful {
    background: linear-gradient(90deg, #e1547f 0.01%, #009de7 70%);
    background-size: 150%;
    background-position: left;
    transition: background-position 0.3s ease;
  }

  .btn-bg-extra {
    background: linear-gradient(93.02deg, #009fce 0%, #005cc9 100%);
    background-size: 150%;
    background-position: left;
    transition: background-position 0.3s ease;
  }
}
